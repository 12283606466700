import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from '@pickleballinc/react-ui';
import clsx from 'clsx';
import React from 'react';

import { TickerEvents } from '@/modules/ticker/types';

interface TickerDropdownProps {
	eventUuid: string;
	handleFilter: (eventUuid: string) => void;
	events: TickerEvents[];
	isMenuOpen: boolean;
	setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TickerDropdown = ({ eventUuid, events, handleFilter, isMenuOpen, setIsMenuOpen }: TickerDropdownProps) => {
	return (
		<div className="flex items-center justify-center border-r">
			<Menu placement="bottom" offset={5} open={isMenuOpen} onOpenChange={(open) => setIsMenuOpen(open)}>
				<Menu.Trigger asChild onClick={() => setIsMenuOpen(!isMenuOpen)}>
					<button className="flex h-8 w-[280px] items-center justify-between rounded-b-2xl border border-gray-300 px-4">
						<p className="w-full truncate text-center text-sm">
							{events.find((event) => event.eventUuid === eventUuid)?.eventTitle || 'Show All'}
						</p>
						<FontAwesomeIcon icon={isMenuOpen ? faChevronUp : faChevronDown} className="ml-2 text-sm text-blue-600" />
					</button>
				</Menu.Trigger>
				<Menu.List className="!max-h-[240px] !w-[280px] !min-w-[200px] overflow-y-scroll sm:!w-auto">
					{events.map((event) => (
						<Menu.Item
							className={clsx(
								'mb-1 flex h-8 cursor-pointer items-center rounded-md px-3 text-sm font-normal hover:bg-gray-100',
								event.eventUuid === eventUuid ? '!bg-blue-600 text-white' : 'text-gray-900'
							)}
							key={event.eventUuid}
							isActive={event.eventUuid === eventUuid}
							onClick={() => handleFilter(event.eventUuid)}
							data-testid={`ticker-event-${event.eventTitle}`}
						>
							<p className="line-clamp-1">{event.eventTitle}</p>
						</Menu.Item>
					))}
				</Menu.List>
			</Menu>
		</div>
	);
};

export default TickerDropdown;
