import React from 'react';

const TickerSkeleton = () => {
	return (
		<div className="min-h-[148px] bg-white" id="pb-ticker-skeleton">
			<div className="mx-auto flex max-w-[1440px]">
				<div className="hidden border-r sm:block sm:w-36">
					<div className="flex size-full flex-col items-center justify-center">
						<div className="mb-2 size-6 animate-pulse rounded bg-gray-200"></div>
						<div className="size-6 animate-pulse rounded bg-gray-200"></div>
					</div>
				</div>

				<div className="w-full">
					<div className="flex items-center justify-center border-r">
						<div className="h-8 w-[280px] animate-pulse rounded-b-2xl bg-gray-200"></div>
					</div>
					<div className="border-r">
						<div className="my-2 flex flex-nowrap justify-center gap-x-4">
							{[1, 2, 3, 4].map((i) => (
								<div key={i} className="flex h-[134px] min-w-[280px] flex-col rounded-lg border border-gray-200 p-4">
									<div className="mb-3 flex items-center justify-between">
										<div className="h-4 w-24 animate-pulse rounded bg-gray-200"></div>
										<div className="h-4 w-16 animate-pulse rounded bg-gray-200"></div>
									</div>
									{[1, 2].map((team) => (
										<div key={team} className="mb-2 flex items-center space-x-3">
											<div className="size-6 animate-pulse rounded-full bg-gray-200"></div>
											<div className="h-4 w-32 animate-pulse rounded bg-gray-200"></div>
											<div className="h-4 w-8 animate-pulse rounded bg-gray-200"></div>
										</div>
									))}
									<div className="flex items-center justify-between">
										<div className="h-4 w-20 animate-pulse rounded bg-gray-200"></div>
										<div className="h-4 w-16 animate-pulse rounded bg-gray-200"></div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TickerSkeleton;
