export const MINIMUM_DATE = '1/1/1900';
export const PBRACKETS_UPLOADS_ROOT = `${process.env.NEXT_PUBLIC_PBRACKETS_IMAGE_URL}/uploads`;
export const proBracketLevelIDs = [2, 3, 4, 7];
export const ContactReasonMap = {
	VOLUNTEER: 'VOLUNTEER_CONTACT_FORM_REASON',
	REFEREES: 'REFEREE_CONTACT_FORM_REASON',
	SPONSORS: 'SPONSOR_CONTACT_FORM_REASON',
	VENDORS: 'VENDOR_CONTACT_FORM_REASON'
};
export const USER_IMPRESSION_KEY = 'user-impression-key';
export const BLOG_LABEL = 'Pickleball Hub';

export enum PageType {
	SearchPage,
	HomePage
}
//Ticker
export const TICKER_PREFETCH_SIZE = '5';
export const TICKER_SCROLL_SIZE = '100';
export const TICKER_MATCH_CARD_WIDTH = 324;
export const HOMEPAGE_COLUMN_NUMBER_OF_WIDGETS = 10;
export const MATCH_CARDS_BUCKET_PATH = 'matchcards';
export const COURT_PRIORITY = [
	'cc',
	'incc',
	's',
	'gs',
	'sc',
	's1',
	'gs1',
	'sc1',
	's2',
	'gs2',
	'sc2',
	's3',
	'gs3',
	'sc3',
	's4',
	'gs4',
	'sc4',
	's5',
	'gs5',
	'sc5',
	's6',
	'gs6',
	'sc6'
];

export const GOOGLE_AUTOCOMPLETE_SCRIPT_ID = 'google-script';
export const TINYMCE_EDITOR_KEY = '2fhjogepu3pkb5q18hb6hzxgg2ojov07jq1mukahjnp7y0my';

export const IS_PRODUCTION = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

export const clubIdLogosMap = new Map<string, string>([
	['f93373ee-79b0-4792-8929-e247f7c3d651', 'lifetime.png'],
	['7036c2a4-2e9b-488e-a614-46537f19798b', 'milp.png'],
	['2a56413a-0012-4d1d-9792-c54138ad1b4e', 'pc_svg_70.svg'],
	['2fb32e18-580c-433a-8d7d-4ad032c45b51', 'paa_svg_70.svg'],
	['dbe502ad-9463-48a5-9808-23040361c613', 'usap_svg_70.svg'],
	['16ce92c6-eeb5-4b68-9b9c-87bf57ceb855', 'ussp_svg_70.svg'],
	['5652dfce-6d3f-48ce-be68-dcaf03646e3d', 'rtm.png'],
	['43088f68-1f44-450f-8c7f-6f7ab3a94581', 'sp_svg_70.svg'],
	['b3e1a5e9-1d0e-4a51-aa8e-70455f428d61', 'pig.webp'],
	['80f17003-cd92-4fc4-84e4-06d38e096042', 'ppa_svg_70.svg'],
	['0a84d0cf-b66a-456a-8273-07ba14ad2c96', 'sanction_ppa_sas.svg'],
	['0c2b4ddc-84d0-4eca-88ec-0224cbafbd9d', 'true_north.png']
]);

export const UPCOMING_TOURNAMENT_MODAL_KEY = 'modal_ignored_tournaments';
