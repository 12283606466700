import { PPA_CLUB_ID } from '@/modules/ticker/constants';
import { MatchInfoV2 } from '@/modules/ticker/types';
import { MatchProps } from '@/types/results.types';

export const shouldShowMatchStartTimeTicker = (match: MatchInfoV2, clubId?: string) => {
	if (clubId && clubId.toLowerCase() === PPA_CLUB_ID.toLowerCase() && match.bracketLevelId === '2') {
		return true;
	}

	const hasRequiredPlayers = () => {
		if (match.formatId === '2') {
			return !!match.teamOnePlayerOneUuid && !!match.teamTwoPlayerOneUuid;
		} else if (match.formatId === '1') {
			return !!match.teamOnePlayerOneUuid && !!match.teamOnePlayerTwoUuid && !!match.teamTwoPlayerOneUuid && !!match.teamTwoPlayerTwoUuid;
		}
		return false;
	};

	const isAssignedOrStartedCompleted = () => {
		const isAssignedToCourt = !!match.localDateMatchAssignedToCourt;
		const isStartedOrCompleted = match.matchStatus === 2 || match.matchStatus === 4;
		return isAssignedToCourt || isStartedOrCompleted;
	};

	return hasRequiredPlayers() && isAssignedOrStartedCompleted();
};

export const shouldShowMatchStartTimeResults = (match: MatchProps) => {
	if (match.clubUuid.toLowerCase() === PPA_CLUB_ID.toLowerCase() && match.bracketLevelTitle === 'Pro') {
		return true;
	}

	const hasRequiredPlayers = () => {
		if (match.formatTitle === 'Singles') {
			return !!match.teamOnePlayerOneUuid && !!match.teamTwoPlayerOneUuid;
		} else if (match.formatTitle === 'Doubles') {
			return !!match.teamOnePlayerOneUuid && !!match.teamOnePlayerTwoUuid && !!match.teamTwoPlayerOneUuid && !!match.teamTwoPlayerTwoUuid;
		}
		return false;
	};

	const isAssignedCourt = () => {
		const isAssignedToCourt = !!match.localDateMatchAssignedToCourt;
		return isAssignedToCourt;
	};

	return hasRequiredPlayers() && isAssignedCourt();
};
